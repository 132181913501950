<script setup lang="ts">
const props = defineProps({
  placeholder: {
    type: String,
    defualt: "",
  },
  height: {
    type: String,
    default: "regular",
  },
  width: {
    type: String,
    default: "",
  },
  font: {
    type: String,
    default: "sans",
  },
  fontSize: {
    type: String,
    default: "base",
  },
  size: {
    type: String || null,
    default: "large",
  },
  min: {
    type: Number,
    required: false,
  },
  max: {
    type: Number,
    required: false,
  },
  isValid: {
    type: Boolean,
    // eslint-disable-next-line vue/no-boolean-default
    default: true,
  },
  typeInput: { type: String, default: "text" },
  styleInput: { type: String, default: "light" },
  outlined: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  filled: { type: Boolean, default: false },
  rounded: {
    type: String || null,
    default: "",
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
});

const emit = defineEmits(["update:modelValue", "focus", "blur", "input"]);

const {
  placeholder,
  size,
  rounded,
  modelValue,
  outlined,
  disabled,
  filled,
  isValid,
  styleInput,
  font,
  fontSize,
  height,
  width,
} = toRefs(props);

function handler(e) {
  emit("update:modelValue", e.target.value);
}

const _height = computed(() => {
  switch (height.value) {
    case "small":
      return "h-7 px-2 py-1";
    case "regular":
      return "h-12 px-4 py-3";
    case "large":
      return "h-13 px-5 py-4";
  }
});
const _width = computed(() => {
  switch (width.value) {
    case "small":
      return "w-29";
    case "regular":
      return "w-62";
    default: ''
  }
});
const _rounded = computed(() => {
  switch (rounded.value) {
    case "":
      return "rounded";
    case "sm":
      return "rounded-sm";
    case "md":
      return "rounded-md";
    case "lg":
      return "rounded-lg";
    case "xl":
      return "rounded-xl";
    case "2xl":
      return "rounded-2xl";
    case "full":
      return "rounded-full";
  }
});

const _font = computed(() => {
  switch (font.value) {
    case "sans":
      return "sans";
  }
});

const _fontSize = computed(() => {
  switch (fontSize.value) {
    case "base":
      return "text-base";
    case "lg":
      return "text-lg";
  }
});

const isActive = ref(false);
const windows = ref();
const isInFocus = ref(false);
const input = ref(null);

function onFocus() {
  emit("focus");
  isInFocus.value = true;
}

function onUnfocus() {
  emit("blur");
  isInFocus.value = false;
}

function setFocusOnInput() {
  input.value.focus();
}

onUnmounted(() => {
  // input.value.removeEventListener('focus', onFocus)
  // input.value.removeEventListener('blur', onUnfocus)
});

const wrapperClasses = computed(() => {
  const classes = [];

  if (styleInput.value === "simple") {
    classes.push("bg-white hover:border hover:border-secondary-400");
  }
  if (styleInput.value === "shadow") {
    classes.push(
      "bg-white shadow-input hover:border hover:border-secondary-400"
    );
  }

  if (styleInput.value === "gray") {
    classes.push("bg-white hover:border hover:border-primary-400");
  }

  if (outlined.value) {
    classes.push("!border !border-primary-50");
  }

  if (isInFocus.value && styleInput.value !== "simple") {
    classes.push("border !border-secondary-400 !hover:border-secondary-400");
  }

  if (filled.value) {
    classes.push("");
  }
  if (!isValid.value) {
    classes.push('border !border-red')
  }
  return classes;
});

const innerClasses = computed(() => {
  const innerClasses = [];
  if (styleInput.value === "simple") {
    innerClasses.push("bg-white hover:border hover:border-secondary-400");
  }

  if (disabled.value) {
    innerClasses.push("bg-white");
  }

  return innerClasses;
});
</script>

<template>
  <div
    class="relative cursor-pointer font-normal text-left rounded space-x-4 flex flex-row items-center border border-transparent"
    :class="[wrapperClasses, _rounded, _font, _fontSize, _height, _width]"
    :disabled="disabled"
    @click="setFocusOnInput"
  >
    <div v-if="$slots.prepend" class="h-full flex items-center my-auto">
      <slot class="w-4 h-4" name="prepend" />
    </div>
    <div class="w-full">
      <input
        ref="input"
        :min="min"
        :maxlength="max"
        :type="typeInput"
        :placeholder="placeholder"
        class="font-normal border-none overflow-hidden text-left text-primary-400 focus:outline-none w-full leading-none"
        :class="innerClasses"
        :disabled="disabled"
        :value="modelValue"
        @input="handler"
        @focus="onFocus"
        @blur="onUnfocus"
      />
    </div>
    <div v-if="$slots.suffix" class="h-full flex items-center">
      <slot name="suffix" />
    </div>

    <template v-if="$slots.popup">
      <slot name="popup" />
    </template>
  </div>
</template>

<style scoped>
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}
</style>
